import { debug } from './Env';
import { isDefined } from './Helper';

export const defaultBlurHash = 'LPF=j=VslV$j1*XnnhWB,[r=VXR*';

export type ImageBucket = 'public' | 'private' | 'temp';

export type BlobOwnerType = 'file' | 'user' | 'conversation' | 'org';

export interface BlobOwner {
  type: BlobOwnerType;
  id: string;
}

export function imageUriGet(
  image: ImageResult | ImagePublic | null | undefined,
): string | null {
  if (!isDefined(image)) {
    return null;
  }

  switch (image.type) {
    case 'uri':
    case 'public':
      return image.uri;
    case 'base64':
      return `data:image/jpg;base64,${image.base64}`;
  }
}

export interface VideoPublic {
  type: 'public';
  uri: string;
  width: number;
  height: number;
  duration: number;
}

export interface AudioPublic {
  type: 'public';
  uri: string;
  duration: number;
}

export interface ImagePublic {
  type: 'public';
  prompt?: string;
  uri: string;
  width: number;
  height: number;
}

export interface ImageUriResult {
  type: 'uri';
  prompt?: string;
  uri: string | null;
  width: number;
  height: number;
}

export type ImageMimeType =
  | 'image/jpeg'
  | 'image/png'
  | 'image/gif'
  | 'image/webp';

export const imageMimeTypes: ImageMimeType[] = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
];

export const imageMimeTypesSet = new Set(imageMimeTypes);

export interface ImageBase64Result {
  type: 'base64';
  base64: string;
  mimeType?: ImageMimeType;
  prompt?: string;
  width: number;
  height: number;
}

export type ImageResult = ImageBase64Result | ImageUriResult | ImagePublic;

export const defaultImage: ImagePublic = {
  type: 'public',
  uri: 'https://public.sociable.bot/file/image_3BmoPC2ZrusC7jEaK4GtT/-Kq8ENwkArQAWK3OAu77J',
  width: 1024,
  height: 1024,
};

export const r2PublicEndpoint = 'https://blob.sociable.bot/';
export const r2PrivateEndpoint = 'https://blob-private.sociable.bot/';
export const r2TempEndpoint = 'https://blob-temp.sociable.bot/';

export function getFilePath(owner: BlobOwner, fileId: string): string {
  return `${debug ? 'debug/' : ''}${owner.type}/${owner.id}/${fileId}`;
}

export function getFileUrl(
  owner: BlobOwner,
  fileId: string,
  bucket: ImageBucket,
): string {
  let endpoint: string;
  switch (bucket) {
    case 'public':
      endpoint = r2PublicEndpoint;
      break;
    case 'private':
      endpoint = r2PrivateEndpoint;
      break;
    case 'temp':
      endpoint = r2TempEndpoint;
      break;
  }

  return `${endpoint}${debug ? 'debug/' : ''}${owner.type}/${
    owner.id
  }/${fileId}`;
}
