import { isDefined } from './Helper';

export const debug = process.env['DEBUG'] == 'true';

export const development = process.env['NODE_ENV'] == 'development';

export const websiteUrl = development
  ? 'https://sfuyzasuyq.a.pinggy.link/'
  : 'https://sociable.bot/';

export const pythonUrl = development
  ? 'http://localhost:8000/'
  : 'https://python.sociable.bot/';

export const sidePanel =
  isDefined(globalThis.window) && window.page == 'sidePanelHome';

export const assetUrl =
  isDefined(globalThis.window) && development
    ? `${window.location.origin}/`
    : 'https://public.sociable.bot/assets/';
