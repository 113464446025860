import { captureClientError } from 'client/frontend/Error';
import { debug } from 'shared/Env';

// mixpanel.init('0dd2fce1dff10a2f65a280ae55f6e9ad', {
//   debug: true,
//   track_pageview: true,
//   persistence: 'localStorage',
// });

export function conversion() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    gtag('event', 'conversion', {
      send_to: 'AW-16840672634/mAdsCPedzpcaEPqKod4-',
      value: 1.0,
      currency: 'USD',
      event_callback: () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (typeof url != 'undefined') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          window.location = url;
        }
      },
    });
  } catch (error) {
    captureClientError('[EVENT] gtag event', error);
    //
  }
}

export function track(
  eventName: string,
  properties?: { [id: string]: string | null | undefined },
  googleParams?: { [id: string]: unknown },
) {
  if (debug) {
    return;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    gtag('event', eventName, googleParams ?? properties);
  } catch (error) {
    captureClientError('[EVENT] gtag event', error);
    //
  }

  // mixpanel.track(eventName, properties);
}

export function identify(userId: string): void {
  if (debug) {
    return;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    gtag('config', 'G-ERV409GEFM', { user_id: userId });
  } catch (error) {
    captureClientError('[EVENT] gtag identify', error);
    //
  }

  // await mixpanel.identify(userId);
}
