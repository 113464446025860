import { track } from 'client/common/provider/EventState';
import { QueryT } from 'shared/Notification';

export type AnalyticEvent =
  | 'USER_CREATE'
  | 'USER_LOGIN'
  | 'MESSAGE_SEND'
  | 'PAGE_DURATION'
  | 'SHARE_LINK';

export function logEvent(
  event: AnalyticEvent,
  params?: { [id: string]: string },
  googleParams?: { [id: string]: unknown },
) {
  console.log(`[EVENT] ${event}${params ? ' ' + JSON.stringify(params) : ''}`);
  track(event, params, googleParams);
}

interface EventPageData {
  path: string;
  query: QueryT | undefined;
  start: number;
}

let currentPage: EventPageData | null = null;

export function logPage(path: string, query?: QueryT) {
  if (currentPage?.path == path) {
    return;
  }

  if (currentPage) {
    logEvent('PAGE_DURATION', {
      path: currentPage.path,
      ...currentPage.query,
      duration: (Date.now() - currentPage.start).toString(),
    });
  }

  logCustomEvent(`PAGE_${path.toUpperCase().replace('/', '_')}`, { ...query });

  logCustomEvent('PAGE_SHOW', {
    path: path,
    ...query,
  });
  currentPage = {
    path,
    query,
    start: Date.now(),
  };
}

let isAppActive = true;

export function logAppActive(isActive: boolean) {
  if (isActive != isAppActive) {
    isAppActive = isActive;
    if (!isAppActive) {
      if (currentPage) {
        logEvent('PAGE_DURATION', {
          path: currentPage.path,
          ...currentPage.query,
          duration: (Date.now() - currentPage.start).toString(),
        });
      }
    } else {
      if (currentPage) {
        logCustomEvent('PAGE_SHOW', {
          path: currentPage.path,
          ...currentPage.query,
        });
        currentPage.start = Date.now();
      }
    }
  }
}

export function logCustomEvent(
  event: string,
  params?: { [id: string]: string | null | undefined },
) {
  console.log(`[EVENT] ${event}${params ? ' ' + JSON.stringify(params) : ''}`);
  void track(event, params);
}
