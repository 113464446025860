import { oneDay } from 'shared/Date';
import { debug } from 'shared/Env';

export const cookieToken = debug ? 'SOCIABLE_TOKEN_DEBUG' : 'SOCIABLE_TOKEN';

export function getCookie(name: string) {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(`${name}=`);
    if (start != -1) {
      start = start + name.length + 1;
      let end = document.cookie.indexOf(';', start);
      if (end == -1) {
        end = document.cookie.length;
      }
      const value = document.cookie.substring(start, end);
      return value;
    }
  }
  return null;
}

export function setCookie(name: string, value: string) {
  document.cookie =
    window.location.hostname == 'localhost'
      ? `${name}=${value}; path=/; expires=${new Date(
          Date.now() + 30 * oneDay,
        ).toUTCString()}`
      : `${name}=${value}; path=/; SameSite=None; Secure; expires=${new Date(
          Date.now() + 30 * oneDay,
        ).toUTCString()}`;
}
