import { extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
import { ImageUriResult } from 'shared/Image';
import { Theme } from 'shared/UserPrivate';
import { assetUrl, sidePanel } from './Env';

extend([mixPlugin]);

export type ColorCategory =
  | 'primary'
  | 'error'
  | 'main'
  | 'secondary'
  | 'tertiary'
  | 'button';

export interface CategoryColors {
  background: string;
  foreground: string;
}

export interface Colors {
  border: string;
  input: string;
  primary: CategoryColors;
  main: CategoryColors;
  error: CategoryColors;
  secondary: CategoryColors;
  tertiary: CategoryColors;
  button: CategoryColors;
}

type PositionT = 'absolute';

export const absoluteFill = {
  position: 'absolute' as PositionT,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};

export type FontWeight =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

export interface StyleT {
  borderRadiusLarge: number;
  borderRadiusMedium: number;
  borderRadiusSmall: number;
  borderRadiusPill: number;
  outlineOffset: {
    extraExtraLargeBold: number;
    extraLargeBold: number;
    largeBold: number;
    mediumBold: number;
    medium: number;
    small: number;
  };
  textExtraExtraLargeBold: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textExtraExtraLargeNormal: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textExtraLargeBold: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textExtraLargeNormal: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textLargeBold: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textLargeNormal: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textMediumBold: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textMediumNormal: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textMediumMono: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textSmallNormal: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textSmallBold: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textSmallMono: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    lineHeight?: number;
    offset?: number;
  };
  textOutline: {
    color: string;
  };
  icon: {
    width: number;
    height: number;
  };
  iconRounded: {
    width: number;
    height: number;
    borderRadius: number;
  };
  largeIcon: {
    width: number;
    height: number;
  };
  largeRoundedIcon: {
    width: number;
    height: number;
    borderRadius: number;
  };
  panelView: {
    maxWidth: number | undefined;
    paddingHorizontal: number;
    width: '100%';
    alignSelf: 'center';
  };
  panelImage: {
    maxWidth: number;
  };
  header: {
    paddingTop: number;
  };
  profileHeader: {
    height: number;
  };
  page: {
    paddingHorizontal: number;
  };
  tabBar: {
    height: number;
  };
  buttonSmall: {
    height: number;
  };
  buttonLarge: {
    height: number;
  };
  listItem: {
    paddingTop: number;
    paddingBottom: number;
  };
  toolbar: {
    height: number;
    paddingTop: number;
    paddingBottom: number;
    buttonWidth: number;
  };
  conversation: {
    maxImageWidth: number;
  };
  mdxEditorClassName: string;
  codeEditorTheme: 'vs-dark' | 'light';
  input: {
    borderWidth: number;
    borderRadius: number;
  };
  avatar: {
    maxWidth: number;
    alignSelf: 'center';
    width: '100%';
    aspectRatio: 1;
  };
  surface: {
    borderWidth: number;
    borderColor: string;
    shadow: boolean;
  };
  conversationInputButtonApp: {
    width: number;
    height: number;
    borderRadius: number;
    borderWidth: number;
    borderColor: string;
    backgroundColor: string;
  };
  conversationInputButtonDesktop: {
    width: number;
    height: number;
    borderRadius: number;
    borderWidth: number;
    borderColor: string;
    backgroundColor: string;
  };
}

export function getStyle(colors: Colors): StyleT {
  return {
    borderRadiusLarge: 16,
    borderRadiusMedium: 8,
    borderRadiusSmall: 4,
    borderRadiusPill: 999,
    surface: {
      borderWidth: 1,
      borderColor: colors.border,
      shadow: true,
    },
    outlineOffset: {
      extraExtraLargeBold: 1,
      extraLargeBold: 0.5,
      largeBold: 0.5,
      mediumBold: 0.5,
      medium: 0.5,
      small: 0.5,
    },
    textExtraExtraLargeBold: {
      fontFamily: 'Raleway',
      fontWeight: '800',
      fontSize: 30,
      lineHeight: 58,
    },
    textExtraExtraLargeNormal: {
      fontFamily: 'Raleway',
      fontWeight: '200',
      fontSize: 30,
      lineHeight: 58,
    },
    textExtraLargeBold: {
      fontFamily: 'Raleway',
      fontWeight: '800',
      fontSize: 20,
      lineHeight: 30,
      offset: 0,
    },
    textExtraLargeNormal: {
      fontFamily: 'Raleway',
      fontWeight: '200',
      fontSize: 20,
      lineHeight: 30,
      offset: 0,
    },
    textLargeBold: {
      fontFamily: 'Raleway',
      fontWeight: '800',
      fontSize: 17,
      lineHeight: 22,
      offset: 0,
    },
    textLargeNormal: {
      fontFamily: 'Raleway',
      fontWeight: '200',
      fontSize: 17,
      lineHeight: 22,
      offset: 0,
    },
    textMediumBold: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 15,
      lineHeight: 22,
      offset: 0,
    },
    textMediumNormal: {
      fontFamily: 'Inter',
      fontWeight: '300',
      fontSize: 15,
      lineHeight: 22,
      offset: 0,
    },
    textMediumMono: {
      fontFamily: 'monospace',
      fontWeight: '300',
      fontSize: 15,
      lineHeight: 22,
      offset: 0,
    },
    textSmallNormal: {
      fontFamily: 'Inter',
      fontWeight: '300',
      fontSize: 10,
      offset: 0,
    },
    textSmallBold: {
      fontFamily: 'Inter',
      fontWeight: '700',
      fontSize: 10,
      offset: 0,
    },
    textSmallMono: {
      fontFamily: 'monospace',
      fontWeight: '300',
      fontSize: 10,
    },
    textOutline: {
      color: 'black',
    },
    header: {
      paddingTop: 12,
    },
    profileHeader: { height: 74 },
    icon: { width: 28, height: 28 },
    iconRounded: {
      width: 28,
      height: 28,
      borderRadius: 999,
    },
    largeIcon: { width: 32, height: 32 },
    largeRoundedIcon: {
      width: 32,
      height: 32,
      borderRadius: 999,
    },
    page: {
      paddingHorizontal: 14,
    },
    panelView: {
      maxWidth: sidePanel ? undefined : 700,
      paddingHorizontal: sidePanel ? 8 : 14,
      width: '100%',
      alignSelf: 'center',
    },
    panelImage: { maxWidth: 400 },
    conversation: {
      maxImageWidth: 300,
    },
    tabBar: {
      height: 53,
    },
    buttonSmall: {
      height: 30,
    },
    buttonLarge: {
      height: 40,
    },
    toolbar: {
      height: 50,
      paddingTop: 8,
      paddingBottom: 8,
      buttonWidth: 28,
    },
    listItem: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    mdxEditorClassName: 'mdxeditor-light',
    codeEditorTheme: 'light',
    input: {
      borderWidth: 1,
      borderRadius: 4,
    },
    avatar: {
      maxWidth: 500,
      alignSelf: 'center',
      width: '100%',
      aspectRatio: 1,
    },
    conversationInputButtonApp: {
      width: 40,
      height: 40,
      borderRadius: 999,
      borderWidth: 2,
      borderColor: colors.border,
      backgroundColor: colors.main.background,
    },
    conversationInputButtonDesktop: {
      width: 40,
      height: 40,
      borderRadius: 999,
      borderWidth: 1,
      borderColor: colors.border,
      backgroundColor: colors.main.background,
    },
  };
}

export interface VideoT {
  video: number;
  firstFrame: ImageUriResult;
}

export const lightColors: Colors = {
  border: 'rgb(90,90,90)',
  input: 'rgb(255,255,255)',
  primary: {
    background: 'rgb(0,111,177)', // #446a88,
    foreground: 'rgb(255,255,255)',
  },
  error: {
    background: 'rgb(227,18,11)',
    foreground: 'rgb(255,255,255)',
  },
  main: {
    background: 'rgb(255, 255, 255)',
    foreground: 'rgb(0,0,0)',
  },
  button: {
    background: 'rgb(0,0,0)', // #2a2926  // 'rgb(28,28,28)',
    foreground: 'rgb(255,255,255)',
  },
  secondary: {
    background: 'rgb(241,241,241)', // #2a2926  // 'rgb(28,28,28)',
    foreground: 'rgb(0,0,0)',
  },
  tertiary: {
    background: 'rgb(247,247,247)', // #fff8e7  // 'rgb(251,251,251)',
    foreground: 'rgb(0,0,0)',
  },
};

export const lightStyle = getStyle(lightColors);

export const darkColors: Colors = {
  border: 'rgb(90,90,90)',
  input: 'rgb(0,0,0)',
  primary: {
    background: 'rgb(255,77,1)', // #446a88,
    foreground: 'rgb(255,255,255)',
  },
  error: {
    background: 'rgb(227,18,11)',
    foreground: 'rgb(255,255,255)',
  },
  main: {
    background: 'rgb(17,17,17)', // #fff8e7  // 'rgb(251,251,251)',
    foreground: 'rgb(255,255,255)',
  },
  button: {
    background: 'rgb(0,0,0)', // #2a2926  // 'rgb(28,28,28)',
    foreground: 'rgb(255,255,255)',
  },
  secondary: {
    background: 'rgb(40,41,41)', // #2a2926  // 'rgb(28,28,28)',
    foreground: 'rgb(255,255,255)',
  },
  tertiary: {
    background: 'rgb(0, 0, 0)',
    foreground: 'rgb(255,255,255)',
  },
};

export const darkStyle: StyleT = {
  ...getStyle(darkColors),
  mdxEditorClassName: 'mdxeditor-dark',
  codeEditorTheme: 'vs-dark',
};

export const cosmicColors: Colors = {
  border: 'rgb(90,90,90)',
  input: 'rgb(255,255,255)',
  primary: {
    background: 'rgb(68, 106, 136)', // #446a88,
    foreground: 'rgb(255,255,255)',
  },
  error: {
    background: 'rgb(227,18,11)',
    foreground: 'rgb(255,255,255)',
  },
  main: {
    background: 'rgb(255, 248, 231)', // #fff8e7  // 'rgb(251,251,251)',
    foreground: 'rgb(0,0,0)',
  },
  button: {
    background: 'rgb(42,41,38)', // #2a2926  // 'rgb(28,28,28)',
    foreground: 'rgb(255,255,255)',
  },
  secondary: {
    background: 'rgb(42,41,38)', // #2a2926  // 'rgb(28,28,28)',
    foreground: 'rgb(255,255,255)',
  },
  tertiary: {
    background: 'rgb(255, 252, 243)',
    foreground: 'rgb(0,0,0)',
  },
};

export const cosmicStyle = getStyle(cosmicColors);

export interface SchemeContextT {
  theme: Theme;
  colors: Colors;
  style: StyleT;
  icon32: string;
  icon192: string;
  icon152: string;
  icon180: string;
  icon167: string;
  icon72: string;
  icon96: string;
  icon144: string;
  icon512: string;
}

export const schemes: Record<Theme, SchemeContextT> = {
  cosmic: {
    theme: 'cosmic',
    colors: cosmicColors,
    style: cosmicStyle,
    icon32: `${assetUrl}logo2-cosmic-32x32.png`,
    icon192: `${assetUrl}logo2-cosmic-192x192.png`,
    icon152: `${assetUrl}logo2-cosmic-152x152.png`,
    icon180: `${assetUrl}logo2-cosmic-180x180.png`,
    icon167: `${assetUrl}logo2-cosmic-167x167.png`,
    icon72: `${assetUrl}logo2-cosmic-72x72.png`,
    icon96: `${assetUrl}logo2-cosmic-96x96.png`,
    icon144: `${assetUrl}logo2-cosmic-144x144.png`,
    icon512: `${assetUrl}logo2-cosmic-512x512.png`,
  },
  light: {
    theme: 'light',
    colors: lightColors,
    style: lightStyle,
    icon32: `${assetUrl}logo2-light-32x32.png`,
    icon192: `${assetUrl}logo2-light-192x192.png`,
    icon152: `${assetUrl}logo2-light-152x152.png`,
    icon180: `${assetUrl}logo2-light-180x180.png`,
    icon167: `${assetUrl}logo2-light-167x167.png`,
    icon72: `${assetUrl}logo2-light-72x72.png`,
    icon96: `${assetUrl}logo2-light-96x96.png`,
    icon144: `${assetUrl}logo2-light-144x144.png`,
    icon512: `${assetUrl}logo2-light-512x512.png`,
  },
  dark: {
    theme: 'dark',
    colors: darkColors,
    style: darkStyle,
    icon32: `${assetUrl}logo2-dark-32x32.png`,
    icon192: `${assetUrl}logo2-dark-192x192.png`,
    icon152: `${assetUrl}logo2-dark-152x152.png`,
    icon180: `${assetUrl}logo2-dark-180x180.png`,
    icon167: `${assetUrl}logo2-dark-167x167.png`,
    icon72: `${assetUrl}logo2-dark-72x72.png`,
    icon96: `${assetUrl}logo2-dark-96x96.png`,
    icon144: `${assetUrl}logo2-dark-144x144.png`,
    icon512: `${assetUrl}logo2-dark-512x512.png`,
  },
};
