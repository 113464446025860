import { StringTable } from 'shared/lang/StringTable';
import { MenuItem } from './Conversation';
import { DBObject } from './DB';
import { debug } from './Env';
import { isDefined, isStringEmpty, MeasurementSystem } from './Helper';
import { ImageUriResult } from './Image';
import { defaultGlobalId } from './UserPublic';

export function isAdmin(userPrivate: UserPrivate | null) {
  return userPrivate?.role == 'admin';
}

export function isDebugOrAdmin(userPrivate: UserPrivate | null) {
  return isDefined(userPrivate) && (debug || userPrivate.role == 'admin');
}

export type UserAppRole = 'user' | 'admin' | 'banned';

export const cookieTheme = 'SOCIABLE_THEME';

export type Theme = 'cosmic' | 'light' | 'dark';

export const themes: Theme[] = ['cosmic', 'light', 'dark'];

export const themesSet = new Set(themes);

export const themeNames: Record<Theme, string> = {
  cosmic: 'Cosmic Latte',
  light: 'Light',
  dark: 'Dark',
};

export interface PhoneData extends DBObject {
  userId: string | null;
  code: string;
  expiration: number | null;
}

export interface EmailData extends DBObject {
  userId: string;
  passwordHash: string | null;
}

export interface PushSubscription {
  endpoint: string;
  expirationTime?: null | number;
  keys: {
    p256dh: string;
    auth: string;
  };
}

export type UserBannedReason =
  | 'bannedAdvertising'
  | 'bannedInauthentic'
  | 'bannedSexual'
  | 'bannedUnderage'
  | 'bannedIllegal';

export const userBannedReasons: UserBannedReason[] = [
  'bannedAdvertising',
  'bannedInauthentic',
  'bannedSexual',
  'bannedUnderage',
  'bannedIllegal',
];

export function userBannedReasonName(
  x: UserBannedReason,
  strings: StringTable,
) {
  return strings[x];
}

// export type UserInviteStatus = 'requested' | 'approved';

// Switch to null if you want to require email & invite for all new users
// Switch to 'requested' if you want to invite (but not email) for all new users
// export const defaultInviteStatus: UserInviteStatus | null = 'approved';

export const defaultBillingMultiplier = 2;
export const defaultBillingPermanentCredit = 5_000_000_000;
export const defaultBillingAnonymousCredit = 0;

export function userPrivateBalance(userPrivate: UserPrivate) {
  if (userPrivate.id == defaultGlobalId) {
    return defaultBillingPermanentCredit;
  }

  return (
    userPrivate.billingBalance +
    userPrivate.billingMonthlyCredit +
    userPrivate.billingPermanentCredit
  );
}

export function getAnonymous(authUser: {
  email?: string | null;
  phoneNumber?: string | null;
}) {
  return isStringEmpty(authUser.email) && isStringEmpty(authUser.phoneNumber);
}

export function getUserPrivateLoading(
  userId: string,
  anonymous: boolean,
  theme: Theme | null,
): UserPrivate {
  const user: UserPrivate = {
    id: userId,
    version: 0,
    created: 0,
    updated: 0,
    role: 'user',
    subscriptions: [],
    units: 'IMPERIAL',
    phone: null,
    email: '',
    emailVerified: true,
    emailAllowed: true,
    emailVerificationCode: null,
    lastLogin: 0,
    theme,
    anonymous: anonymous,
    billingBalanceUpdateScheduled: 0,
    billingBalance: 0,
    billingLastUpdate: 0,
    billingPermanentCredit: defaultBillingPermanentCredit,
    billingMonthlyCredit: 0,
    notificationCount: 0,
  };
  return user;
}

export type AvailabilityDay = { start: number; end: number }[];

export type AvailabilityWeek = [
  AvailabilityDay,
  AvailabilityDay,
  AvailabilityDay,
  AvailabilityDay,
  AvailabilityDay,
  AvailabilityDay,
  AvailabilityDay,
];

export interface Availability {
  minNotice: number;
  breakAfterMeeting: number;
  week: AvailabilityWeek;
  maxFutureDays: number;
  subscriptions?: {
    uri: string;
  }[];
}

export const defaultAvailability: Availability = {
  minNotice: 24 * 60,
  breakAfterMeeting: 0,
  maxFutureDays: 90,
  week: [
    [],
    [{ start: 540, end: 1020 }],
    [{ start: 540, end: 1020 }],
    [{ start: 540, end: 1020 }],
    [{ start: 540, end: 1020 }],
    [{ start: 540, end: 1020 }],
    [],
  ],
};

export const minAvailability: Availability = {
  minNotice: 30,
  maxFutureDays: 90,
  breakAfterMeeting: 0,
  week: [
    [{ start: 360, end: 1380 }],
    [{ start: 360, end: 1380 }],
    [{ start: 360, end: 1380 }],
    [{ start: 360, end: 1380 }],
    [{ start: 360, end: 1380 }],
    [{ start: 360, end: 1380 }],
    [{ start: 360, end: 1380 }],
  ],
};

export interface UserPrivate extends DBObject {
  role: UserAppRole;
  subscriptions?: PushSubscription[];
  units: MeasurementSystem;
  phone: string | null;
  email: string | null;
  emailVerified: boolean;
  emailAllowed: boolean;
  emailVerificationCode: string | null;
  lastLogin: number;
  theme?: Theme | null;
  anonymous?: boolean;
  toolIds?: string[];
  tools?: {
    [id: string]: {
      menuItems?: MenuItem[];
    };
  };
  forceCheapVoices?: boolean;
  billingBalanceUpdateScheduled: number;
  billingBalance: number;
  billingLastUpdate: number;
  billingPermanentCredit: number;
  billingMonthlyCredit: number;
  stripeCustomerId?: string | null;
  bannedReason?: UserBannedReason | null;
  notificationCount: number;
  lastEvent?: number;
  availability?: Availability | null;
  unreadPost?: number;
}

export interface ReadyPlayerMe extends DBObject {
  readyPlayerMeUserId: string;
}

export interface PromoCode extends DBObject {
  userId: string | null;
  freeDays: number;
}

export interface UserPrivateSetFieldsInput {
  fields: Partial<UserPrivate>;
}

export interface AdminUserPrivateGetInput {
  userId: string;
}

export interface AdminUserPrivateGetOutput {
  userPrivate: UserPrivate;
}

export interface UserPrivateAddSubscriptionInput {
  subscription: PushSubscription;
}

export interface AdminUserPrivateBanInput {
  userId: string;
  bannedReason: UserBannedReason;
}

export interface AdminUserPrivateUnbanInput {
  userId: string;
}

export interface AdminUserPrivateDeleteInput {
  userId: string;
}

export interface UserPrivateSetVerificationImageInput {
  type: 'face' | 'age';
  image: ImageUriResult;
}

export interface UserPrivateSendFreeSubscriptionInput {
  freeUserId: string;
  freeDays: number;
}

export interface UserPrivateCreateOfferInput {
  freeDays: number;
}

export interface UserPrivateCreateOfferOutput {
  id: string;
}

export interface UserPrivateRedeemOfferInput {
  id: string;
}

export interface UserPrivateReadyPlayerMeTokenInput {
  userId: string;
}

export interface UserPrivateReadyPlayerMeTokenOutput {
  token: string;
}

export interface AdminUserPrivateGetBalanceInput {
  userId: string;
}

export interface AdminUserPrivateGetBalanceOutput {
  balance: number;
}

export interface UserPrivateBadgeCountClearInput {
  conversationId: string | null;
}

export interface UserPrivateToolAddInput {
  botId: string;
}

export interface UserPrivateToolRemoveInput {
  botId: string;
}

export interface UserPrivateToolContextMenuSetInput {
  botId: string;
  menuItems: MenuItem[] | null;
}

export interface UserPrivateToolPinnedSetInput {
  botId: string;
  pinned: boolean;
}

export function getEmailVerification(userPrivate: UserPrivate | null): boolean {
  if (!isDefined(userPrivate)) {
    return false;
  }

  if (invalidPhoneNumber(userPrivate) && unverifiedEmail(userPrivate)) {
    return false;
  }
  return true;
}

function invalidPhoneNumber(userPrivate: UserPrivate): boolean {
  return !isDefined(userPrivate.phone) || userPrivate.phone.length <= 0;
}

function unverifiedEmail(userPrivate: UserPrivate): boolean {
  const emailVerified = userPrivate.emailVerified;
  return userPrivate.email === null || !emailVerified;
}

export type UserPrivateFunctionsT =
  | 'userPrivateAddSubscription'
  | 'adminUserPrivateGet'
  | 'userPrivateUpdate'
  | 'adminUserPrivateBan'
  | 'adminUserPrivateUnban'
  | 'userPrivateReadyPlayerMeToken'
  | 'adminUserPrivateGetBalance'
  | 'adminUserPrivateDelete'
  | 'userPrivateBadgeCountClear'
  | 'userPrivateToolAdd'
  | 'userPrivateToolRemove'
  | 'userPrivateToolPinnedSet';
