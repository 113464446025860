import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { StringTable } from 'shared/lang/StringTable';
import { Mood } from './Conversation';
import { DBObject } from './DB';
import { ErrorType } from './Functions';
import { isDefined, isStringEmpty } from './Helper';
import { ImagePublic, ImageResult, ImageUriResult } from './Image';
import { Timezone } from './Timezone';
import { UserGender } from './UserGender';
import { UserPrivate } from './UserPrivate';

export function sessionIdUserIdGet(sessionId: string): string {
  const index = sessionId.indexOf('+');
  return sessionId.slice(0, index);
}

export function sessionIdCreate(userId: string): string {
  return `${userId}+${nanoid()}`;
}

export const defaultGlobalId = 'global';

export type UserLang =
  | 'ar'
  | 'de'
  | 'en'
  | 'es'
  | 'fa'
  | 'fr'
  | 'hi'
  | 'it'
  | 'ja'
  | 'pt'
  | 'ru'
  | 'tl'
  | 'tr'
  | 'vi'
  | 'zh';

export type UserLangAutoT = UserLang | 'auto';

export const userLangs: UserLang[] = [
  'ar',
  'de',
  'en',
  'es',
  'fa',
  'fr',
  'hi',
  'it',
  'ja',
  'pt',
  'ru',
  'tl',
  'tr',
  'vi',
  'zh',
];

export const userLangAutos: UserLangAutoT[] = ['auto', ...userLangs];

export const userLangsSet = new Set(userLangs);

export const userLangShortNames: Record<UserLang, string> = {
  en: 'EN',
  es: 'ES',
  fr: 'FR',
  zh: '中',
  de: 'DE',
  hi: 'हिं',
  it: 'IT',
  ja: '日',
  pt: 'PT',
  ru: 'Ру',
  tr: 'Tü',
  vi: 'Ti',
  ar: 'AR',
  fa: 'FA',
  tl: 'TL',
};

export const userLangEnglishNames: Record<UserLang, string> = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  zh: 'Chinese',
  de: 'German',
  hi: 'Hindi',
  it: 'Italian',
  ja: 'Japanese',
  pt: 'Portuguese',
  ru: 'Russian',
  tr: 'Turkish',
  vi: 'Vietnamese',
  ar: 'Arabic',
  fa: 'Farsi',
  tl: 'Tagalog',
};

export const userLangLongNames: Record<UserLang, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  zh: '中文',
  de: 'Deutsch',
  hi: 'हिंदी',
  it: 'Italiano',
  ja: '日本語',
  pt: 'Português',
  ru: 'Русский',
  tr: 'Türkçe',
  vi: 'Tiếng Việt',
  ar: 'Arabic',
  fa: 'Farsi',
  tl: 'Tagalog',
};

export const userLangCodes: Record<UserLang, string> = {
  en: 'en',
  es: 'es',
  fr: 'fr',
  zh: 'zh',
  de: 'de',
  hi: 'hi',
  it: 'it',
  ja: 'ja',
  pt: 'pt',
  ru: 'ru',
  tr: 'tr',
  vi: 'vi',
  ar: 'ar',
  fa: 'fa',
  tl: 'tl',
};

export const defaultLang: UserLang = 'en';

export type UserSearchSort = 'distance' | 'created';

export const userSearchSortValues: UserSearchSort[] = ['created', 'distance'];

export const bannedNamesRegex = /nigger|dick/i;

export const defaultName = 'Human';

export interface Avatar {
  id: string;
  uri: string | null;
  image: ImagePublic;
  background: ImagePublic | null;
}

export interface AvatarUpdate {
  id: string;
  uri: string | null;
  image: ImageResult;
  background: ImageResult | null;
}

export const defaultBackground: ImagePublic = {
  type: 'public',
  uri: 'https://public.sociable.bot/debug/user/jPZOv4FyW3xRiTNSV4Rx3/PmpMes8FCG-VbCV00N14D',
  width: 1024,
  height: 1024,
};

export const defaultAvatar: Avatar = {
  id: '677d6ac22554a00ff1842e1f',
  uri: null,
  image: {
    type: 'public',
    uri: 'https://public.sociable.bot/default_avatar.jpeg',
    width: 1024,
    height: 1024,
  },
  background: defaultBackground,
};

export function getUserLoading(id: string): UserPublic {
  const user: UserPublic = {
    id,
    activated: false,
    name: '',
    version: 1,
    created: 0,
    updated: 0,
  };
  return user;
}

export const testUsers: { [id: string]: string } = {
  '+12065551212': '111111',
  '+14255551212': '111111',
  '+14155551212': '111111',
  '+15305551212': '111111',
  '+12075551212': '111111',
  '+12025550178': '046290',
  '+573124453275': '111111',
  '+12268996919': '111111',
  '+12065551234': '111111',
  '+12065559876': '111111',
};

export interface UserPublic extends DBObject {
  activated: boolean;
  name: string;
  bio?: string | null;
  avatar?: Avatar | null;
  birthday?: number | null;
  lang?: UserLang;
  phoneMd5?: string | null;
  isBot?: boolean;
  voiceId?: string;
  mood?: Mood | null;
  lastFile?: number;
  timezone?: Timezone;
}

export interface UserPublicUpdate {
  name: string;
  bio: string | null;
  avatar: AvatarUpdate;
  birthday: number | null;
  lang: UserLang;
  pronouns: string[] | null;
  gender: UserGender | null;
  voiceId: string;
  mood: Mood | null;
  timezone: Timezone;
}

export interface UserCreateForPhoneInput {
  phone: string;
}

export interface UserCreateForPhoneOutput {
  user: UserPublic;
}

export interface UserGetByPhoneInput {
  phone: string;
  createIfNotExists: boolean;
}

export interface UserGetByPhoneOutput {
  activated: boolean;
  userId: string | null;
}

export interface UserGetByEmailInput {
  email: string;
  name?: string;
  createIfNotExists: boolean;
}

export interface UserGetByEmailOutput {
  user: UserPublic | null;
}

export interface UserInviteInput {
  friendId: string;
  conversationId: string | null;
}

export interface UserLoadInput {
  lang: UserLang;
  timezone: Timezone;
}

export interface UserLoadOutput {
  user: UserPublic;
  userPrivate: UserPrivate;
  token: string;
  firstLoad: boolean;
}

export interface UserGetOrCreateInput {
  email?: string;
  phone?: string;
  name?: string;
}

export interface UserGetOrCreateOutput {
  user: UserPublic;
}

export interface UserSendVerificationCodeInput {
  appName?: string | undefined;
  phone: string;
}

export interface UserVerifyCodeInput {
  phone: string;
  code: string;
}

export interface UserVerifyCodeOutput {
  token: string | null;
  user: UserPublic | null;
  error: ErrorType | null;
}

export interface UserEmailExistsInput {
  email: string;
}

export interface UserEmailExistsOutput {
  exists: boolean;
  password: boolean;
}

export interface UserEmailVerifyInput {
  userId: string;
  email: string;
  code: string;
}

export interface UserEmailUnsubscribeInput {
  userId: string;
  conversationId: string | null;
}

export interface UserEmailLoginInput {
  email: string;
  password: string | null;
}

export interface UserGoogleLoginInput {
  credential: string;
}

export interface UserEmailResetInput {
  email: string;
}

export interface UserEmailSetEmailInput {
  email: string;
  emailAllowed: boolean;
}

export interface UserEmailReSendVerificationEmailInput {
  email: string;
}

export interface UserSetPasswordInput {
  password: string;
}

export interface UserSetFieldsInput {
  fields: Partial<UserPublicUpdate>;
}

export interface UserSetFieldsOutput {
  error: ErrorType | null;
  user: UserPublic;
}

export interface UserBlockInput {
  otherUserId: string;
}

export interface UserGetPhoneInput {
  userId: string;
}

export interface UserGetPhoneOutput {
  phone: string | null;
}

export interface UserSetAvatarImageInput {
  image: ImageUriResult;
}

export interface UserRequestDeleteInput {
  phone: string;
}

export interface UserDeepgramKeyOutput {
  key: string;
}

export interface UserCookieGetInput {
  idToken: string;
}

export interface UserCookieGetOutput {
  cookie: string;
}

export interface UserCookieLoginInput {
  cookie: string;
}

export interface UserSetPhoneInput {
  phone: string | null;
}

export function getAge(birthday: number | null | undefined): number | null {
  if (!isDefined(birthday)) {
    return null;
  }
  return Math.floor(dayjs().diff(dayjs(birthday), 'years'));
}

export function userCheckName(name: string, strings: StringTable) {
  if (isStringEmpty(name.trim())) {
    throw new Error(strings.errorNameRequired);
  }

  if (bannedNamesRegex.test(name)) {
    throw new Error(strings.errorNameBanned);
  }
}

export function isUserDeleted(user: UserPublic): boolean {
  return user.name === 'deleted';
}

export type UserPublicFunctionsT =
  | 'userGetByPhone'
  | 'userGetByEmail'
  | 'userGetOrCreate'
  | 'userDelete'
  | 'userEmailSetEmail'
  | 'userSetFields'
  | 'userGetPhone'
  | 'userDeepgramKey'
  | 'userSetPassword'
  | 'userLoad'
  | 'userSetPhone'
  | 'userInvite';

export type UserPublicRequestsT =
  | 'userCreateForPhone'
  | 'userSendVerificationCode'
  | 'userVerifyCode'
  | 'userEmailVerify'
  | 'userEmailUnsubscribe'
  | 'userEmailLogin'
  | 'userEmailReset'
  | 'userGoogleLogin'
  | 'userAnonymousLogin';
