declare global {
  interface Window {
    page: PageT;
    query: QueryT;
    isIOS: boolean;
    isMobile: boolean;
  }
}

if (typeof __DEV__ === 'undefined') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  global.__DEV__ = process.env.NODE_ENV !== 'production';
}

import { decodeJwt } from 'jose';
import { schemes } from 'shared/DefaultStyle';
import { getEnumFromString, isStringEmpty } from 'shared/Helper';
import { getQueryOptional, QueryT } from 'shared/Notification';
import { PageT } from 'shared/Pages';
import { cookieTheme, themesSet } from 'shared/UserPrivate';
import { cookieToken, getCookie, setCookie } from './frontend/Cookie';
import { captureClientError } from './frontend/Error';
import { initSocket } from './frontend/Socket';
import { userAnonymousLogin } from './frontend/UserPublic';

function getEmbed() {
  try {
    return window.self !== window.top && window.self.name != 'simulator';
  } catch (e) {
    return true;
  }
}

async function start() {
  const theme = getEnumFromString(getCookie(cookieTheme), themesSet, 'dark');
  const scheme = schemes[theme];
  const page: PageT = window.page;
  const query: QueryT = window.query;

  const isEmbed = getEmbed();
  const anon = getQueryOptional(query, 'anon') == 'true';
  delete query['colors'];
  delete query['source'];
  delete query['embed'];
  const isIOS = window.isIOS;
  const isMobile = window.isMobile;
  const isDesktop = !isEmbed && !isMobile;

  let userId: string | null = null;
  let anonymous = true;
  let token = getCookie(cookieToken);
  try {
    if (!isStringEmpty(token)) {
      const payload = decodeJwt(token);
      userId = payload['userId']! as string;
      anonymous = payload['anonymous']! as boolean;
    } else if (anon) {
      token = (await userAnonymousLogin()).token!;
      setCookie(cookieToken, token);
      const payload = decodeJwt(token);
      userId = payload['userId']! as string;
      anonymous = payload['anonymous']! as boolean;
    }
  } catch (error) {
    captureClientError(`[AUTH] invalid token ${token}`, error);
  }

  const socket = !isStringEmpty(token) && userId ? initSocket(token) : null;
  const { ClientInit } = await import('./clientInternal');
  ClientInit({
    socket,
    isDesktop,
    isEmbed,
    isIOS,
    scheme,
    userId,
    anonymous,
    router: { target: { page, query } },
  });
}

if (process.env['NODE_ENV'] != 'production') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  module.hot.accept();
}

void start();
