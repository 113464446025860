import { call, request, track } from 'client/frontend/Socket';
import { DBObject } from 'shared/DB';
import { ErrorOutput } from 'shared/Functions';
import { insertSorted, isDefined, UnsubscribeT } from 'shared/Helper';
import {
  StoreGetFileInput,
  StoreGetFileOutput,
  StoreGetFilesInput,
  StoreGetFilesOutput,
  StoreTrackDocInput,
  StoreTrackDocOutput,
  StoreTrackDocsInput,
  StoreTrackDocsOutput,
} from 'shared/Store';
import { Socket } from 'socket.io-client';
import { logError } from './Error';

export type DocResult<T> = (x: T) => void;
export type FileOrNullResult<T> = (x: T | null) => void;
export type DocsResult<T> = (x: T[]) => void;

export async function getDoc<T extends DBObject>(
  socket: Socket | null,
  input: StoreGetFileInput,
): Promise<T | null> {
  if (!isDefined(socket)) {
    return (
      await request<StoreGetFileInput, StoreGetFileOutput>('storeGetDoc', input)
    ).item as T | null;
  }

  return (
    await call<StoreGetFileInput, StoreGetFileOutput>(
      socket,
      'storeGetDoc',
      input,
    )
  ).item as T | null;
}

export async function getDocs<T extends DBObject>(
  socket: Socket | null,
  input: StoreGetFilesInput,
): Promise<T[]> {
  if (!isDefined(socket)) {
    return (
      await request<StoreGetFilesInput, StoreGetFilesOutput>(
        'storeGetDocs',
        input,
      )
    ).items as T[];
  }

  return (
    await call<StoreGetFilesInput, StoreGetFilesOutput>(
      socket,
      'storeGetDocs',
      input,
    )
  ).items as T[];
}

export function trackDoc<T extends DBObject>(
  socket: Socket | null,
  input: StoreTrackDocInput,
  cb: DocResult<T>,
): UnsubscribeT {
  if (!isDefined(socket)) {
    getDoc<T>(socket, input)
      .then((x) => {
        if (isDefined(x)) {
          cb(x);
        }
      })
      .catch(logError);
    return () => {
      // do nothing
    };
  }

  return track<StoreTrackDocInput, StoreTrackDocOutput>(
    socket,
    'storeTrackDoc',
    input,
    (x) => {
      if (!isDefined(x.item)) {
        return;
      }
      cb(x.item as T);
    },
  );
}

export function trackDocOrNull<T extends DBObject>(
  socket: Socket,
  input: StoreTrackDocInput,
  cb: FileOrNullResult<T>,
): UnsubscribeT {
  return track<StoreTrackDocInput, StoreTrackDocOutput>(
    socket,
    'storeTrackDoc',
    input,
    (x) => {
      cb(x.item as T | null);
    },
  );
}

export function trackDocs<T extends DBObject>(
  socket: Socket,
  input: StoreTrackDocsInput,
  cb: DocsResult<T>,
): UnsubscribeT {
  let items: DBObject[] | null = null;
  return track<StoreTrackDocsInput, StoreTrackDocsOutput>(
    socket,
    'storeTrackDocs',
    input,
    (x) => {
      switch (x.type) {
        case 'initial':
          items = x.items;
          break;
        case 'update':
          if (isDefined(items)) {
            items = insertSorted(
              items.filter((y) => y.id != x.item.id),
              (a, b) => b.created - a.created,
              'replace',
              x.item,
            );
          }
          break;
        case 'delete':
          if (isDefined(items)) {
            items = items.filter((y) => y.id != x.id);
          }
          break;
      }

      if (isDefined(items)) {
        cb([...items] as T[]);
      }
    },
  );
}

export function adminStoreResetCache(socket: Socket): Promise<ErrorOutput> {
  return call(socket, 'adminStoreResetCache', {});
}
