export function captureClientError(message: string, error?: unknown) {
  // JUSTIN: import('@sentry/react')
  //   .then((Sentry) => {
  //     Sentry.captureException(error ?? new Error(message), {
  //       extra: { message },
  //     });
  //   })
  //   .catch(() => {
  //     //
  //   });
  console.error(message, error);
}

export function logError(err: unknown) {
  captureClientError(`[ASYNC] error ${err} ${JSON.stringify(err)}`);
}

export function safeAsync<T>(name: string, cb: Promise<T> | undefined) {
  cb?.then(() => {
    console.log(`[ASYNC] ${name} finished`);
  }).catch((err: unknown) => {
    captureClientError(
      `[ASYNC] ${name} error ${err} ${JSON.stringify(err)}`,
      err,
    );
  });
}
